<template>
  <div id="loginPage">
    <div class="form">
      <div class="text-center">
        <h2>Login</h2>
      </div>
      <div class="d-flex input" :class="formInputIndex === 0 ? 'focus' : 'nofocus'">
        <div>
          <v-icon class="icon">
            mdi-account
          </v-icon>
        </div>
        <div style="width: 100%">
          <input type="text" @click="formInputChange(0)" placeholder="account" v-model="account" />
        </div>
      </div>
      <div class="d-flex input" :class="formInputIndex === 1 ? 'focus' : 'nofocus'">
        <div>
          <v-icon class="icon">mdi-lock-outline</v-icon>
        </div>
        <div style="width: 100%">
          <input type="password" @click="formInputChange(1)" placeholder="password" v-model="password" />
        </div>
      </div>
      <div class="d-flex input" :class="formInputIndex === 2 ? 'focus' : 'nofocus'">
        <div style="width: 120px;padding-bottom:2px" class="d-flex">
          <img :src="captchaUrl" style="width:120px;height:40px;" @click="refreshCaptcha" />
        </div>
        <div style="width:100%;">
          <input type="text" @click="formInputChange(2)" placeholder="code" v-model="captcha" />
        </div>
      </div>
      <div class="buttonGroup">
        <button @click="login" :disabled="this.account === ''
          || this.password === '' || this.captcha === ''">登入</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import PortalUsers from '@/apis/PortalUsers';
import store from '@/store/index'

export default {
  name: 'tenantListView',
  data: () => ({
    formInputIndex: 0,
    account: '',
    password: '',
    captcha: '',
    verificationTicket: '',
    captchaUrl: `${Vue.prototype.baseURL}/CaptchaImage`,
  }),

  watch: {
  },

  created() {
  },

  methods: {
    formInputChange(index) {
      if (this.formInputIndex !== index) {
        this.formInputIndex = index;
      }
    },
    refreshCaptcha() {
      const time = new Date().getTime();
      this.verificationTicket = time;
      this.captcha = '';
      this.captchaUrl = `${Vue.prototype.baseURL}api/Home/CaptchaImage?token=${time}`;
    },
    async login() {
      // this.$router.push('/tenantList')
      const params = {
        accountName: this.account,
        password: this.password,
        // verificationTicket: this.verificationTicket.toString(),
      };
      const res = await PortalUsers.loginApi(this.captcha, this.verificationTicket, params);
      if (res.data.error === 'success') {
        localStorage.setItem('ticket', res.data.result.token);
        store.set('app/globalToken',res.data.result.token)
        this.$router.push('/tenantList');
        Vue.$toast.open({
          message: 'login success',
          type: 'success',
          position: 'top-right',
        });
      }
      this.refreshCaptcha();
    }
  },
  created() {
    // localStorage.removeItem('ticket');
    this.refreshCaptcha();
  },
};
</script>
<style lang="sass" scoped>
#loginPage
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  .form
    box-shadow: 0 0 5px #999
    padding: 50px
    .input
      align-items: center
      padding: 10px 0 0 0
      line-height: 2.5
      width: 250px
      input
        outline: none
        width: 100%
        margin-left: 5px
        line-height: 2.5
.d-flex
  display: flex
.text-center
  text-align: center
.focus
  animation: test .3s ease
  border-bottom: 2px solid #58BA6D
  .icon
    color: #58BA6D
.nofocus
  border-bottom: 1px solid #999
.text-focus
  color: #58BA6D

.buttonGroup
  display: flex
  justify-content: center
  align-items: center
  height: 60px
  margin: 20px 0
  button:not([disabled])
    background-color: #58BA6D
    width: 120px
    height: 40px
    border-radius: 10px
    color: #fff
  button:disabled
    background-color: #bce3c4
    width: 120px
    height: 40px
    border-radius: 10px
    color: #fff
  button:hover:not([disabled])
    box-shadow: 0 0 5px #999
    text-shadow: 0 0 5px #999
    font-weight: 900


@keyframes test
  from
    width: 160px
  to
    width: 250px
</style>
